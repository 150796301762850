// extracted by mini-css-extract-plugin
export var upsellPage = "UpsellPage-module--upsellPage--25MZs";
export var fullImage = "UpsellPage-module--fullImage--2VYX7";
export var content = "UpsellPage-module--content--3NgLR";
export var titleContainer = "UpsellPage-module--titleContainer--1pCDj";
export var title = "UpsellPage-module--title--2yTW2";
export var titleDescription = "UpsellPage-module--titleDescription--3Jvn5";
export var offerContainer = "UpsellPage-module--offerContainer--2on85";
export var offerTitle = "UpsellPage-module--offerTitle--Ni93X";
export var offerDescription = "UpsellPage-module--offerDescription--3fGIR";
export var productContainer = "UpsellPage-module--productContainer--2yfZt";
export var imageContainer = "UpsellPage-module--imageContainer--5PKrc";
export var imageWrapper = "UpsellPage-module--imageWrapper--2hSlR";
export var productImage = "UpsellPage-module--productImage--7sPou";
export var flagContainer = "UpsellPage-module--flagContainer--2gTaT";
export var flagImage = "UpsellPage-module--flagImage--3-_Qk";
export var flagText = "UpsellPage-module--flagText--3OiOc";
export var triangle = "UpsellPage-module--triangle--3okv5";
export var triangleIcon = "UpsellPage-module--triangleIcon--32LYR";
export var formDescriptionImage = "UpsellPage-module--formDescriptionImage--XED_H";
export var offerDescriptionImage = "UpsellPage-module--offerDescriptionImage--1v3oU";
export var offerDescriptionTextImage = "UpsellPage-module--offerDescriptionTextImage--6cnrN";
export var offerDescriptionPrices = "UpsellPage-module--offerDescriptionPrices--1T6OD";
export var formDescription = "UpsellPage-module--formDescription--1Z5gC";
export var offerDescriptionText = "UpsellPage-module--offerDescriptionText--2Edkl";
export var offerDontPass = "UpsellPage-module--offerDontPass--1sCL6";
export var button = "UpsellPage-module--button--14lQT";
export var agreeContainer = "UpsellPage-module--agreeContainer--2T1BF";
export var agree = "UpsellPage-module--agree--2Y2D4";
export var declineOffer = "UpsellPage-module--declineOffer--3MQ2c";
export var declineOfferLink = "UpsellPage-module--declineOfferLink--zo-Xu";
export var footerContainer = "UpsellPage-module--footerContainer--2FyZC";