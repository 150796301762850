import React, { useState, useEffect } from 'react'
import { Link , graphql } from "gatsby";
import { connect } from 'react-redux'
import { trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { navigate } from 'utils/navigate'
import ProgressiveImage from 'react-progressive-image'
import flagImage from 'assets/flag.svg'
import zoomImage from 'assets/zoom.svg'

import { Button, Footer, Header, Modal, Notification, PreventDirectLink, FacebookPixels, SEO } from 'components'
import { actionCreators as actionHomeCreators, selectMenu, selectCartId } from 'state/HomePage/reducer'
import { selectOrderId } from 'state/BillingPage/reducer'
import * as actions from 'state/UpsellPage/actions'
import { selectors } from 'state/UpsellPage/reducer'

import * as styles from './UpsellPage.module.scss'

const UpsellPage = ({ cartId, addUpsellToOrder, upsellState, menu, resetMenuFilters, setMenuCollection, data }) => {
  const { shared, landing } = useSiteMetadata()

  let pageTag = data.markdownRemark?.frontmatter?.GOOGLE_SALE_CONVERTION_SNIPPET;
  if (pageTag) {
    pageTag = pageTag.replace(/\[%\{script\}%\]/g, '')
    pageTag = pageTag.replace(/\[%\{\/script\}%\]/g, '')
    pageTag = pageTag.replace(/\[%\{script\s/g, '<script ')
  }

  const { prices } = upsellState

  const isPrice = size => {
    const price = prices.find(el => !!el.attributes.find(a => a.value === size))
    return price ? (price.price / 100).toFixed(2) : ''
  }

  const brandName = landing.CAMPAIGN.name

  // Lets define next page route
  let nextPagePath = '/thankyou'

  if (shared.HAS_STICKERS_PAGE) {
    nextPagePath = '/sticker'
  }

  if (!shared.HAS_STICKERS_PAGE && shared.HAS_UPGRADE_PAGE) {
    nextPagePath = '/upgrade'
  }

  const [isFullImage, setIsFullImage] = useState(false)
  // history.listen(listener => {
  //   if (listener.pathname.match(/(product)|(shipping)|(billing)|(upsell)|(sticker)|(upgrade)/)) {
  //     history.goForward()
  //   }
  // })

  const [notification, showNotification] = useState()

  useEffect(() => {
    trackingGA()
    const script = document.createElement('script')

    script.text = pageTag

    if (pageTag) {
      document.head.appendChild(script)
    }

    return () => {
      if (pageTag) {
        document.head.removeChild(script)
      }
    }
  }, [])

  useEffect(() => {
    showNotification(upsellState.UIState.error)
  }, [upsellState.UIState.error])

  const handleConfirmUpsellClicked = async () => {
    const paid = await addUpsellToOrder()
    if (paid.payload?.isSaved) {
      navigate(nextPagePath)
    }
  }

  const handleCollectionClicked = collection => {
    setMenuCollection(collection)
  }

  const handleReset = () => {
    resetMenuFilters()
  }

  return (
    <div className={styles.upsellPage}>
      <SEO pathname='/upsell' />
      <PreventDirectLink cartId={cartId} />
      <FacebookPixels id={shared.FACEBOOK_PIXEL_ID} event='AddToWishlist' />
      {isFullImage && (
        <Modal onClose={() => setIsFullImage(false)}>
          <ProgressiveImage
            src={upsellState.selectedProductPrice?.originalImageUrl || upsellState.selectedProductPrice?.imageUrl}
            placeholder={upsellState.selectedProductPrice?.previewImageUrl}
          >
            {src => <img src={src} alt='' className={styles.fullImage} />}
          </ProgressiveImage>
        </Modal>
      )}
      <div className={styles.content}>
        <Header menu={menu} onCollectionClicked={handleCollectionClicked} onResetCollection={handleReset} />
        <div className={styles.titleContainer}>
          <p className={styles.title}>Wait! Your Order Is Not Complete!</p>
          <p className={styles.titleDescription}>Congratulations, You've been selected for our special event!</p>
        </div>

        <div className={styles.offerContainer}>
          <p className={styles.offerTitle}>
            Get the <span>Limited Edition</span> Exclusive T-Shirt for <span>75%</span> OFF!
          </p>
          <p className={styles.offerDescription}>Only 14/100 shirts left worldwide!</p>
        </div>

        <div className={styles.productContainer}>
          <div className={styles.imageContainer}>
            <div className={styles.imageWrapper}>
              <ProgressiveImage src={upsellState.selectedProductPrice?.imageUrl} placeholder={upsellState.selectedProductPrice?.previewImageUrl}>
                {src => <img src={src} alt='' className={styles.productImage} />}
              </ProgressiveImage>
            </div>

            <div className={styles.flagContainer}>
              <img src={flagImage} alt='' className={styles.flagImage} />
              <p className={styles.flagText}>Made In USA</p>
            </div>
            <div className={styles.triangle} onClick={() => setIsFullImage(true)}>
              <img src={zoomImage} alt='' className={styles.triangleIcon} />
            </div>
          </div>

          <div className={styles.formDescriptionImage}>
            <div className={styles.offerDescriptionImage}>
              <p className={styles.offerDescriptionTextImage}>
                Thank you for your support to the “{brandName}” community. We’re offering you one of our most favorite designs thats not available to the public! We only print
                limited runs of these exclusive t-shirt so grab em while we still have them in stock.
              </p>
              <p className={styles.offerDescriptionPrices}>
                Pricing are as follows (includes S&H):
                <br />
                SM - XL: ${isPrice('SM')}
                <br />
                2XL: ${isPrice('2XL')}
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className={styles.formDescription}>
          <div className={styles.offerDescription}>
            <p className={styles.offerDescriptionText}>
              Congratulations on getting your new shirt to support your passion! We're offering you this <span>exclusive one time offer</span> in getting the Limited Edition
              Exclusive T-Shirt!
            </p>
          </div>

          <div className={styles.offerDontPass}>
            <p>Don't pass this up!</p>
          </div>

          <div className={styles.button}>
            <Button value='Yes, Please add this to my order.' path={nextPagePath} customAction={handleConfirmUpsellClicked} loading={upsellState.UIState.isLoading} />
          </div>
          <div className={styles.agreeContainer}>
            <div className={styles.agree}>
              By pressing 'Continue', you agree to claim your exclusive shirt for <br />
              75% off and be charged ${((upsellState?.selectedProductPrice?.price || 0) / 100).toFixed(2)} on top of your existing order.
            </div>
          </div>
          <div className={styles.declineOffer}>
            <Link to={nextPagePath} className={styles.declineOfferLink}>
              No, thanks. I decline this offer
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
        <Notification message={notification} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  cartId: selectCartId(state),
  orderId: selectOrderId(state),
  upsellState: selectors.selectUpsellState(state),
  menu: selectMenu(state)
})

const mapDispatchToProps = {
  addUpsellToOrder: actions.addUpsellToOrder,
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection
}

export default connect(mapStateToProps, mapDispatchToProps)(UpsellPage)

export const query = graphql`
  query UpsellPage {
    markdownRemark(fields: {slug: {eq: "/upsell/"}}) {
      frontmatter {
        GOOGLE_SALE_CONVERTION_SNIPPET
      }
    }
  }
`
